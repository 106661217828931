<template>
  <InnerScroll
    :transparent="showChat"
    :full-height="true"
    :title="showChat ? null : $t('counselingEvent.chat.noChatTitle')"
    :loading="pageDataLoading"
  >
    <the-chat
      v-if="showChat"
      :show-closed-message="showCounselingEventClosedMessage"
      :current-user-chat="counselingEventChat"
      :chat-title="
        $t('counselingEvent.chat.title', {
          subject: activeChatCounselingEvent.subject,
          time: activeChatCounselingEvent ? formatTime(activeChatCounselingEvent.beginning, activeChatCounselingEvent.end) : ''
        })
      "
      :has-active-chat="true"
      @closed-chat-info-read="handleClosedChatInfoRead"
      @close-chat="handleCloseChat"
      @cancel-chat="handleCancelChat"
    />
    <template v-else>
      <p class="no-chat">
        <template v-if="chatDidNotStartYet">{{
          $t('counselingEvent.chat.didNotStartYet', { startTime })
        }}</template>
        <template v-else-if="chatFinished">{{
          $t('counselingEvent.chat.finished')
        }}</template>
      </p>
    </template>
  </InnerScroll>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TheChat from 'organisms/TheChat/TheChat'
import InnerScroll from '../InnerScroll/InnerScroll.vue'
import { fromTo } from '../../../shared/date/format'

export default {
  components: {
    TheChat,
    InnerScroll
  },
  computed: {
    ...mapGetters([
      'activeChatCounselingEventById',
      'userInfo',
      'pageDataLoading',
      'chats'
    ]),
    showCounselingEventClosedMessage() {
      return (
        this.counselingEventChat &&
        ['closed', 'finished'].includes(
          this.counselingEventChat.status
        ) &&
        this.counselingEventChat.closedInfoRead === false &&
        this.counselingEventChat.closedBy !== this.userInfo.id
      )
    },
    counselingEventChat() {
      return this.activeChatCounselingEvent
        ? this.chats.filter(el => el.eventId === this.counselingEventId).pop()
        : null
    },
    activeChatCounselingEvent() {
      return this.activeChatCounselingEventById(this.counselingEventId)
    },
    counselingEventId() {
      return this.$route.params.id
    },
    chatDidNotStartYet() {
      return this.activeChatCounselingEvent && !this.counselingEventChat
    },
    chatFinished() {
      return (
        this.counselingEventChat &&
        this.counselingEventChat.status === 'finished'
      )
    },
    showChat() {
      return (
        this.counselingEventChat &&
        (['active'].includes(this.counselingEventChat.status) ||
          this.showCounselingEventClosedMessage)
      )
    },
    startTime() {
      if (!this.activeChatCounselingEvent) {
        return ''
      }
      return this.$options.filters.time(
        new Date(this.activeChatCounselingEvent.beginning).toISOString()
      )
    }
  },
  created() {
    this.loadCounselingEventChat(this.counselingEventId)
  },
  methods: {
    ...mapActions(['loadCounselingEventChat']),
    handleClosedChatInfoRead() {
      this.redirectToOverview()
    },
    handleCloseChat() {
      this.redirectToOverview()
    },
    handleCancelChat() {
      this.redirectToOverview()
    },
    redirectToOverview() {
      this.$router.push({ name: 'counselingEventsOverview' })
    },
    formatTime(start, end) {
      return fromTo(new Date(start), new Date(end), false)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../LiveChat/_assets/LiveChat';
</style>
